import React from "react"

const employment = [
  {
    company: "company one",
    role: "Front End",
  },
  {
    company: "company two",
    role: "Web Developer",
  },
]

const EmploymentHistory = () => {
  return (
    <div className="text-left max-w-xl mx-auto">
      <div className="grid grid-cols-2 gap-2 mt-5">
        {employment.map(({ role, company }) => (
          <>
            <div className="flex justify-end font-bold">
              <p>{role}</p>
            </div>
            <p>{company}</p>
          </>
        ))}
      </div>
    </div>
  )
}

export default EmploymentHistory
